import "@fortawesome/fontawesome-free/css/all.css";
import { createVuetify } from "vuetify";
import { pt, en } from "vuetify/locale";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases, fa } from "vuetify/iconsets/fa";

export default createVuetify({
  components,
  directives,
  locale: {
    locale: "pt",
    fallback: "en",
    messages: { pt, en },
  },
  icons: {
    defaultSet: "fa",
    aliases,
    sets: {
      fa,
    },
  },
});
