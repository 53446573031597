export default {
  pt: {
    language: {
      pt: {
        label: "Português",
        flag: "br",
      },
      en: {
        label: "Inglês",
        flag: "us",
      },
    },
    app_bar: {
      home: "Início",
      products: "Produtos",
      services: "Serviços",
      articles: "Artigos",
      about: "Sobre",
      language: "Idioma",
    },
    general: {},
    home_view: {
      welcome_phrase: "Transformamos suas idéias em ferramentas.",
      welcome_text_1:
        "Através do uso de tecnologias avançadas, presentes no mercado, desenvolvemos suas idéias, de maneira ágil, com boas práticas, metodologia, e muito conhecimento.",
      welcome_text_2:
        "Você nos conta suas idéias e nós desenvolvemos seu software. Beleza, simplicidade e funcionalidade. ",
      gamification: {
        title: "Gamificação de Processos",
        description:
          "Seus processos, com avaliação por pontos, gerando uma série de indicadores de qualidade, aproveitando ao máximo o pontencial de sua equipe",
      },
      process_application: {
        title: "Processos Industriais ou Empresariais",
        description:
          "Aplique realidade aumentada tanto em processos indústriais quanto em processos empresariais com a mesma facilidade.",
      },
    },
    product_view: {
      welcome_phrase: "Nossos produtos",
    },
    service_view: {
      welcome_phrase: "Nossos serviços ",
    },
    about_view: {
      welcome_phrase: "Sobre nós",
    },
  },
  en: {
    language: {
      pt: {
        label: "Portuguese",
        flag: "br",
      },
      en: {
        label: "English",
        flag: "us",
      },
    },
    app_bar: {
      home: "Home",
      products: "Products",
      services: "Services",
      articles: "Artigos",
      about: "About",
      language: "Language",
    },
    general: {},
    home_view: {
      welcome_phrase: "We transform your ideas into tools.",
      welcome_text_1:
        "Através do uso de tecnologias avançadas, presentes no mercado, desenvolvemos suas idéias, de maneira ágil, com boas práticas, metodologia, e muito conhecimento.",
      welcome_text_2:
        "Through the use of advanced technologies, present in the market, we develop your ideas, in an agile way, with good practices, methodology, and a lot of knowledge.",
      gamification: {
        title: "Process Gamification",
        description:
          "Your processes, with evaluation by points, generating a series of quality indicators, making the most of your team’s potential",
      },
      process_application: {
        title: "Industrial or Business Processes",
        description:
          "Apply augmented reality to both industrial and business processes with the same ease.",
      },
    },
    product_view: {
      welcome_phrase: "Our Products",
    },
    service_view: {
      welcome_phrase: "Our Services",
    },
    about_view: {
      welcome_phrase: "About Us",
    },
  },
};
