<template>
  <v-app id="lab4tech" class="bg-light-green-lighten-4">
    <v-app-bar
      app
      color="light-green-lighten-4 text-teal-lighten-1"
      light
      elevation="6"
      elevate-on-scroll
    >
      <template v-slot:prepend>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
      </template>
      <v-img
        :src="require('@/assets/images/logo.png')"
        height="70"
        position="left"
      ></v-img>
      <v-spacer></v-spacer>
      <v-btn to="/" height="72">{{ $t("app_bar.home") }}</v-btn>
      <v-btn to="/products" height="72">{{ $t("app_bar.products") }}</v-btn>
      <v-btn to="/services" height="72">{{ $t("app_bar.services") }}</v-btn>
      <v-btn to="/about" height="72">{{ $t("app_bar.about") }}</v-btn>
      <v-menu transition="scale-transition">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" text>
            <span :class="`fi fi-${$t(`language.${$i18n.locale}.flag`)}`" />
          </v-btn>
        </template>
        <v-list density="compact">
          <v-list-item
            v-for="locale in $i18n.availableLocales"
            :key="`locale-${locale}`"
            @click="changeLanguage(locale)"
          >
            <template v-slot:prepend>
              <span :class="`fi fi-${$t(`language.${locale}.flag`)}`" />
            </template>
            {{ $t(`language.${locale}.label`) }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-footer app class="text-light-green-lighten-4 bg-teal-lighten-1">
      <v-spacer />
      {{ new Date().getFullYear() }}—<strong>LAB4Tech</strong>
    </v-footer>
  </v-app>
</template>
<script>
export default {
  name: "App",
  mounted() {
    console.log("Ready...");
  },
  methods: {
    changeLanguage(newLocale) {
      this.$i18n.locale = newLocale;
      this.$vuetify.locale = newLocale;
    },
  },
};
</script>
<style lang="scss"></style>
